import React, { useEffect } from 'react';
import Modal from 'react-modal';

const getModalWidth = () => {
  if (window.innerWidth >= 768) {
    return '50%';
  } else {
    return '90%';
  }
};

const CustomModal = ({ isOpen, onRequestClose, contentLabel, children }) => {
  useEffect(() => {
    const updateWidth = () => {
      const modalContent = document.querySelector(".ReactModal__Content");
      if (modalContent) {
        modalContent.style.width = getModalWidth();
      }
    };

    window.addEventListener("resize", updateWidth);

    // Initial call to set width
    if (isOpen) {
      updateWidth();
    }

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#401a6e",
          width: getModalWidth(),
          margin: 0,
          padding: 0,
        },
        overlay: {
          background: "rgba(11, 2, 18, 0.75)",
        },
      }}
      ariaHideApp={false}
    >
      <button
        onClick={onRequestClose}
        style={{
          float: "right",
          height: "40px",
          marginRight: "7px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
          />
        </svg>
      </button>
      <div>{children}</div>
    </Modal>
  );
};

export default CustomModal;
