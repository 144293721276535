import React, { useState, useEffect } from "react";
import CustomModal from "../Modals";
import Offers from "./Offers";

export default function ShopButton() {
  const [open, setOpen] = useState(true);

  function toggleOpen() {
    setOpen((open) => !open);
  }

  return (
    <>
      <CustomModal
        isOpen={open}
        onRequestClose={toggleOpen}
        contentLabel="SHOP"
        children={<Offers />}
      />
    </>
  );
}
