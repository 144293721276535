import React, { useEffect, useState } from "react";
import { getSoftOffers, OffersTypes } from "./query/offers";
import CustomModal from "../Modals";
import BuyCoinsWithRubies from "./BuyCoinsWithRubies";
import GotIt from "../Modals/GotIt";

interface SoftOffersProps {
  setIsAnimatingCoins: (isAnimatingCoins: boolean) => void;
  totalRubies: number;
  updateUserAmount: (offerId: string, offerType: string) => void;
}
export default function SoftOffers({
  setIsAnimatingCoins,
  totalRubies,
  updateUserAmount,
}: SoftOffersProps) {
  const [open, setOpen] = useState(false);
  const [softOffers, setSoftOffers] = useState<OffersTypes[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<OffersTypes | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [noMoreRubies, setNoMoreRubies] = useState(false);

  useEffect(() => {
    fetchSoftOffers();
  }, []);

  async function fetchSoftOffers() {
    setLoading(true);
    try {
      const softData = await getSoftOffers();
      setSoftOffers(softData);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <h1 className="text-white font-chakra">Loading...</h1>;
  if (error)
    return <h1 className="text-red font-anton">Something went wrong...</h1>;

  function toggleOpen(item) {
    if (item.pay_amount > totalRubies) {
      setNoMoreRubies(true);
      return;
    }
    setOpen((open) => !open);
    setSelectedOffer(item);
  }

  function closeModal() {
    setOpen(false);
  }

  function closeNoRubiesModal() {
    setNoMoreRubies(false);
  }

  return (
    <>
      <div className="buy-container">
        {softOffers.map((item: OffersTypes) => {
          return (
            <div
              key={item.id}
              className="buy-container-content gap-[2px]"
              style={{
                borderRight: "1px solid #1B062F",
                height: "141px",
              }}
            >
              <p className="text-gold font-anton font-[400] font-[16px]">
                x{item.receive_amount}
              </p>
              <img
                className="w-[31px] h-[30px]"
                src="../img/public/coin_sm.png"
              />
              <div className="coins-for-rubies font-anton">
                <img
                  className="w-[18px] h-[11px]"
                  src="../img/public/rubin_sm.png"
                />
                {item.pay_amount}
              </div>
              <button
                onClick={() => toggleOpen(item)}
                className="buy-coins-btn font-anton"
                style={{
                  width: "90px",
                  height: "40px",
                  backgroundColor: "#F49331",
                  color: "#1B062F",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                BUY
              </button>
              <CustomModal
                isOpen={open}
                onRequestClose={toggleOpen}
                contentLabel="BUY"
                children={
                  selectedOffer && (
                    <BuyCoinsWithRubies
                      receive_amount={selectedOffer?.receive_amount}
                      pay_amount={selectedOffer?.pay_amount}
                      coinId={selectedOffer?.id}
                      offerType={selectedOffer.offer_type}
                      closeModal={closeModal}
                      setIsAnimatingCoins={setIsAnimatingCoins}
                      updateUserAmount={updateUserAmount}
                    />
                  )
                }
              />
            </div>
          );
        })}
        <GotIt
          isOpen={noMoreRubies}
          onRequestClose={closeNoRubiesModal}
          contentLabel="No Rubies"
          children={
            <div className="flex justify-center items-center p-4">
              <p className="font-[600] text-white text-center text-[20px]">
                NOT ENOUGH RUBIES TO MAKE THAT PURCHASE. BUY RUBIES AND TRY
                AGAIN.
              </p>
            </div>
          }
        />
      </div>
    </>
  );
}
