import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import TagManager from 'react-gtm-module'
import {useUser} from "./UserContext";
import Cookies from "js-cookie";

export const CookieScripts = () => {
    const {user} = useUser();

    useEffect(() => {
        const storedUserId = Cookies.get('cr8_userId');
        if (user?.userId && !storedUserId) {
            TagManager.dataLayer({
                dataLayer: {
                    user_id: user.userId,
                },
            });
            Cookies.set('cr8_userId', true);
        }
    }, [user]);
    return (
    <Helmet>
      {/* <!-- Google Tag Manager --> */}
      <script>
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-55Z82SD4');
          `}
      </script>
      <noscript>
        {`<iframe
          title='googletagmanager'
          src='https://www.googletagmanager.com/ns.html?id=GTM-55Z82SD4'
          height='0'
          width='0'
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>`}
      </noscript>
      {/* <!-- End Google Tag Manager --> */}

      {/* <!--  Meta Pixel Code --> */}
      <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '354138333813430');
          fbq('track', 'PageView');
          `}</script>

      <noscript>
        {` <img
          alt='Meta Pixel Code'
          height='1'
          width='1'
          style={{ display: 'none' }}
          src='https://www.facebook.com/tr?id=354138333813430&ev=PageView&noscript=1'
        />`}
      </noscript>
      {/* <!-- End  Meta Pixel Code --> */}
    </Helmet>
  );
};
