import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module'

interface User {
  id: string;
  sessionid: string;
  userId: string;
  created: string;
  verifychannel: string;
  sent_to: string;
  created_at: string;
  email: string;
  phone: string;
  country: string;
  last_signin: string;
  username: string;
  profile_pic: string;
  marketing: boolean;
  min: boolean; // indicates the admin status
}

interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);  
  const session_token = Cookies.get('c8_session_token');
  
  useEffect(() => {
    if (!session_token) {
      setUser(null);
      setLoading(false);
      return;
    }

    const checkSession = async () => {
      try {
        const response = await fetch(`${process.env.API_URL}/verifysession/${session_token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.API_KEY || '',
          },
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          TagManager.dataLayer({
            dataLayer: {
              user_id: userData.userId,
            },
          });
          Cookies.set('cr8_userId', true);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Failed to check session', error);
        setUser(null);
      } finally {
        setLoading(false); 
      }
    };

    checkSession().then(() => {
      console.debug('checked session');
    });
  }, [session_token]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
